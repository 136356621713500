<template>
  <div>
    <div v-if="error">
      <v-alert outlined type="error" prominent border="left">
        {{ errors }}
      </v-alert>
    </div>
    <div v-if="!sended">
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Сброс пароля</v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon large to="/login" v-on="on">
                <v-icon>mdi-home</v-icon>
              </v-btn>
            </template>
            <span>Страница входа</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon large to="/register" v-on="on">
                <v-icon>mdi-clipboard-account</v-icon>
              </v-btn>
            </template>
            <span>Регистрация</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              label="Ваш email"
              name="email"
              prepend-icon="mdi-message"
              type="text"
            ></v-text-field>
          </v-form>
          Нет аккаунта?
          <router-link to="/register">Зарегистрируйтесь</router-link>.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click.prevent="resetHandler"
            :loading="loading"
            :disabled="loading"
            >Отправить запрос</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
    <div v-else>
      <v-alert outlined type="success" prominent border="left">
        На Ваш email отправлена ссылка и код для сброса пароля. Код будет
        действителен один час.
        <v-btn color="blue lighten-2" text to="/#/login">
          На страницу входа
        </v-btn>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { sendRequest } from '../utils/graphql';

export default {
  name: 'AskReset',
  data: () => ({
    loading: false,
    error: false,
    errors: '',
    sended: false,
    email: ''
  }),
  methods: {
    async resetHandler() {
      this.errors = '';
      const query = `
        mutation AuthAskReset($email: String) {
          authAskReset(email: $email)
        }
      `;
      const vars = {
        email: this.email
      };
      this.loading = true;
      const result = await sendRequest('', query, vars);
      this.loading = false;

      if (
        result.errors &&
        Array.isArray(result.errors[0].data) &&
        result.errors[0].data.length > 0
      ) {
        result.errors[0].data.forEach(element => {
          this.errors += element.message + ' ';
          this.error = true;
        });
      } else {
        this.error = false;
        this.sended = true;
      }
    }
  }
};
</script>

<style></style>
